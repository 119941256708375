.contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  .contact-info {
    max-width: 800px;
    width: 100%;
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-info h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #333;
  }
  
  .contact-info .section {
    margin-bottom: 2rem;
  }
  
  .contact-info h2 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    color: #333;
  }
  
  .contact-info p {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: #555;
  }
  
  /* .contact-info a {
    color: #007bff;
    text-decoration: none;
  } */
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  .report-button {
    margin-top: 2rem;
    display: block;
    width: 100%;
    max-width: 300px;
  }

  .report-button a {
    color: #ffffff;
    text-decoration: none;
  }