.file-grid {
    padding: 20px;
  }
  
  .file-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .file-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .file-description {
    margin-top: 8px;
    font-size: 0.9rem;
    color: #666;
  }
  
  .download-button {
    color: blue;
    text-transform: none;
    font-size: 1rem;
  }
  
  @media (max-width: 600px) {
    .file-title {
      font-size: 1rem;
    }
    
    .file-description {
      font-size: 0.8rem;
    }
  }