.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(5, 49, 9) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1500px;
  padding: 0 20px; /* Added padding for better alignment */
}

.navbar-logo {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.menu-icon {
  display: none;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

@media screen and (max-width: 960px) {
  .menu-icon {
      display: block;
      color: #fff;
      font-size: 1.8rem;
      cursor: pointer;
  }

  .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      background: #242222;
      z-index: 1;
  }

  .nav-menu.active {
      left: 0;
      opacity: 1;
  }

  .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
  }

  .nav-links:hover {
      background-color: #fff;
      color: #242424;
  }

  .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
  }
}